const isEmptyValue = (value) => [null, undefined].indexOf(value) !== -1;

Storage.prototype.setObject = function(key, value) {
  value = !isEmptyValue(value) ? JSON.stringify(value) : null;
  this.setItem(key, value);
};

Storage.prototype.getObject = function(key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};

export const getData = data => window.localStorage.getObject(data) || false;
export const setData = (key, data) => window.localStorage.setObject(key, data);
