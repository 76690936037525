import React, { Component } from 'react';
import Clock from 'react-live-clock';
import uuid from 'uuid/index.js';
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl';

import ChatBubble from '../components/ChatBubble';

export default class DateTimeBar extends Component {
  delete = e => {
    e.preventDefault();
  };

  render() {
    return (
      <div className="topbar">
        <ChatBubble key={uuid()}>
          <Clock format={'HH:mm'} ticking={true} interval={60} />
          {`${format(new Date(), 'dddd D MMMM YYYY', { locale: nlLocale })}`}
        </ChatBubble>
      </div>
    );
  }
}
