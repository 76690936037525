import format from 'date-fns/format';

export const greeting = () => {
  const hour = format(new Date(), 'HH');
  let greeting = 'Goedendag!';

  if (hour < 6) {
    greeting = 'Goedenacht!';
  } else if (hour < 12) {
    greeting = 'Goedemorgen!';
  } else if (hour < 18) {
    greeting = 'Goedemiddag!';
  } else if (hour < 25) {
    greeting = 'Goedenavond!';
  }
  return greeting;
};
