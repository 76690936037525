import React, { Component } from 'react';

import NavLink from '../components/NavLink';
import PageHeader from '../components/PageHeader';

import user from '../util/userManagement';

export default class Logout extends Component {
  constructor() {
    super();
    user.deleteUser();
  }

  render() {
    return (
      <div className="page">
        <PageHeader pageTitle="U bent uitgelogd" showLogo={true} />
        <NavLink to="/" className="button">Opnieuw inloggen</NavLink>
      </div>
    );
  }
}
