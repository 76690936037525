import React, { Component } from 'react';
import { spring, Motion } from 'react-motion';
import { browserHistory, Link } from 'react-router';
import Parser from 'html-react-parser';
import uuid from 'uuid/index.js';

import ChatBubble from '../components/ChatBubble';
import UserChatBubble from '../components/UserChatBubble';
import DateTimeBar from '../components/DateTimeBar';

import user from '../util/userManagement';
import wait from '../util/wait';
import { getEvents } from '../util/api';
import { greeting } from '../util/dates';
import format from 'date-fns/format';

import '../assets/css/chat.css';
import phone from '../assets/img/phone.svg';
import skype from '../assets/img/skype.svg';

const REFRESH_INTERVAL = 4 * 60 * 60 * 1000;

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

export default class Chat extends Component {
  constructor() {
    super();
    this.state = {
      userName: user.getName(),
      events: [],
      messages: [],
      buttonState: 'request',
    };

    this.refreshInterval = null;
  }


  async componentWillMount() {
    if (!user.appReady()) {
      browserHistory.push('/settings/group');
    } else {
      this.fetchAndHandleEvents();
    }
  }

  /**
   * Check if state has change **to** 'request', then start
   * the refresh interval. If the state has changes **from** 'request'
   * then kill the inteval. 
   * 
   * @param {*} prevProps 
   * @param {*} prevState 
   */
  componentDidUpdate(prevProps, prevState) {
    this.scrollDown();

    if (this.state.buttonState !== prevState.buttonState) {

      if (prevState.buttonState === 'request') {
        this.stopRefreshInterval();
      }

      if (this.state.buttonState === 'request') {
        this.startRefreshInterval();
      }
    }
  }

  /**
   * Load events from server, clear messages and add the new events
   */
  async fetchAndHandleEvents() {
    const events = await getEvents(user.getAuth(), user.getGroupId());

    this.clearMessages();
    this.createMessages(
      (events || []).sort((a, b) => new Date(a.Start.datetime) - new Date(b.Start.datetime))
    );   
    
    this.restartRefreshInterval();
  }

  restartRefreshInterval() {
    this.stopRefreshInterval();
    this.startRefreshInterval();
  }

  stopRefreshInterval() {
    clearInterval(this.refreshInterval);
  }

  startRefreshInterval() {
    this.refreshInterval = setInterval(() => this.refreshMessages(), REFRESH_INTERVAL);
  }

  async refreshMessages() {
    await this.fetchAndHandleEvents();  
  }

  clearMessages() {
    this.setState({
      events: [],
      messages: []
    });
  }

  createMessages = events => {
    const eventList = [<ChatBubble key={uuid()}>{greeting()}</ChatBubble>];
    const delayedEventList = [];

    if (events && events.length) {
      eventList.push(
        <ChatBubble key={uuid()}>
          {Parser(
            `Vandaag ${events.length > 1
              ? ' staan er <b>' + events.length + ' afspraken</b>'
              : 'staat er <b>1 afspraak</b>'} op je agenda.`
          )}
        </ChatBubble>
      );

      const RealEvents = events.map(chat => {
        const message = `
                ${chat.caretaker ? `<img src=${chat.caretaker.image} role="presentation"/>` : ''}

                ${chat.Start.datetime !== 'False'
                  ? `${chat.End.datetime !== 'False' ? 'Van' : 'Om'} ${format(chat.Start.datetime, 'HH:mm')}`
                  : 'Vandaag'}
                ${chat.End.datetime !== 'False' ? `tot ${format(chat.End.datetime, 'HH:mm')}` : ''}
                staat er <b>${chat.title}</b> op de agenda.
                ${chat.caretaker ? `${chat.caretaker.first_name} regelt dit.` : ''}
              `;
        return <ChatBubble key={uuid()}>{Parser(message)}</ChatBubble>;
      });
      eventList.push(...RealEvents);
    } else {
      eventList.push(
        <ChatBubble key={uuid()}>{Parser(`Vandaag staan er <b>geen</b> afspraken op je agenda.`)}</ChatBubble>
      );
    }
    eventList.push(<ChatBubble key={uuid()}>{`Kan ik je verder ergens mee helpen?`}</ChatBubble>);
    
    const start = async () => {
      await asyncForEach(eventList, async (event) => {
        await wait(1000);
        delayedEventList.push(event);
        this.setState({ events, messages: delayedEventList});
      })
    }
    
    start();

  };

  addMessage = async (text, type) => {
    const { messages } = this.state;
    const Bubble = type === 'user' ? UserChatBubble : ChatBubble;
    messages.push(<Bubble key={uuid()}>{text}</Bubble>);
    
    await wait(type === 'user' ? 0 : 1000);
    this.setState({ messages });
  };

  addCallMessage = async e => {
    e.preventDefault();
    await this.addMessage('Ik wil iemand bellen', 'user');
    await this.addMessage('Wie wil je bellen?');
    this.setState({ buttonState: 'call' });
  };

  addVidMessage = async e => {
    e.preventDefault();
    await this.addMessage('Ik wil iemand beeldbellen', 'user');
    await this.addMessage('Wie wil je beeldbellen?');
    this.setState({ buttonState: 'vid' });
    
  };

  resetButtonState = async e => {
    await this.addMessage(e.target.innerText, 'user');
    this.setState({ buttonState: 'request' });
    await this.addMessage('Kan ik je verder ergens mee helpen?');
  };

  scrollDown() {
    setTimeout(() => {
      document.querySelector('.page').scrollTop = 2000;
    }, 100);
  }

  render() {
    const { buttonState } = this.state;

    let buttonList = [];
    if (buttonState === 'call') {
      buttonList = user.getPhoneNumbers().map(number => {
        return (
          <a className="button" key={uuid()} href={'tel:' + number.key} onClick={this.resetButtonState}>
            {number.name}
          </a>
        );
      });
      buttonList.push(
        <button className="button" key={uuid()} onClick={this.resetButtonState}>
          Niemand
        </button>
      );
    } else if (buttonState === 'vid') {
      buttonList = user.getSkypeNames().map(number => {
        return (( number.key.startsWith("https://") ?
          <Link 
        className="button"
          key={uuid()}
          to="/spreekuur">{number.name}</Link>
        :
          <a
            className="button"
            key={uuid()}
            href={number.type === 'ms-sfb' ?
              `ms-sfb://call?id=${number.key}&video=true`
              : number.key.startsWith("https://")
              ? `${number.key}` : `skype:${number.key}?call&video=true`
            }
            onClick={this.resetButtonState}
          >
            {number.name}
          </a>
        ));
      });
      buttonList.push(
        <button className="button" key={uuid()} onClick={this.resetButtonState}>
          Niemand
        </button>
      );
    }

    const motionMessages = this.state.messages.map((message, i) => {
      return (
        <Motion
          key={i}
          defaultStyle={{ opacity: 0, maxHeight: 0 }}
          style={{ opacity: spring(1), maxHeight: spring(300, { stiffness: 70, damping: 5 }) }}
        >
          {interpolatingStyle => <div style={{ overflow: 'hidden', ...interpolatingStyle }}>{message}</div>}
        </Motion>
      );
    });

    return (
      <div className="wrapper">
        <DateTimeBar />
        <div className="chat page">
          <div className="chatlist">{motionMessages}</div>

          {buttonState === 'request' ? (
            <div className="buttonbar">
              <button className="button" onClick={this.addCallMessage}>
                Bellen <img src={phone} alt="" />
              </button>
              <button className="button" onClick={this.addVidMessage}>
                Beeld <img src={skype} alt="" />
              </button>
            </div>
          ) : (
            <div className="buttonlist">{buttonList}</div>
          )}
        </div>
      </div>
    );
  }
}
