import React, { Component } from "react";

import NavLink from "./NavLink";

import logo from "../assets/img/logo.svg";
import "../assets/css/header.css";

export default class Header extends Component {
  render() {
    
    const active = window.location.pathname === "/spreekuur";

    return (
      <header>
        <nav>
          <NavLink to="/settings" className={active? "active" : ""}>
            <img src={logo} alt="Settings" />
          </NavLink>
          <NavLink to="/" onlyActiveOnIndex={true}>
            <img src={logo} alt="Chat" />
          </NavLink>
        </nav>
      </header>
    );
  }
}
