import React from 'react';

import logo from '../assets/img/logo.svg';
import '../assets/css/pageHeader.css';

const PageHeader = ({ showLogo, pageTitle }) =>
  <div className="page-header">
    {showLogo ? <img src={logo} alt="" /> : ''}
    <h2>{pageTitle}</h2>
  </div>;

export default PageHeader;
