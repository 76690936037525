import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import PageHeader from '../../components/PageHeader';
import NavLink from '../../components/NavLink';

import group from '../../assets/img/group.svg';
import phone from '../../assets/img/phone.svg';
import skype from '../../assets/img/skype.svg';
import '../../assets/css/settings.css';

export default class Settings extends Component {
  handleLogout = () => {
    const shouldLogout = window.confirm(
      `Weet je zeker dat je wilt uitloggen?`,
    );

    if (shouldLogout) {
      browserHistory.push('/logout');
    }
  };

  render() {
    return (
      <div className="Settings page">
        <PageHeader pageTitle="Instellingen" />
        <div className="settings-menu">
          <NavLink to="/settings/group">Groep wijzigen <img src={group} alt="" /></NavLink>
          <NavLink to="/settings/phonenumbers">Telefoonnummers <img src={phone} alt="" /></NavLink>
          <NavLink to="/settings/skypenames">Skypenamen <img src={skype} alt="" /></NavLink>
        </div>
        <p>Voor meer informatie, ga naar <a href="http://nettie.nu/goedendag">nettie.nu</a></p>
        <p><button onClick={this.handleLogout} className="button logout-link">Uitloggen</button></p>
      </div>
    );
  }
}
