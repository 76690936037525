import React from 'react';

import logo from '../assets/img/logo.svg';
import '../assets/css/chatbubble.css';

const ChatBubble = ({ children }) => (
  <div className="chatbubble">
    <img src={logo} alt="" />
    <div className="bubble">{children}</div>
  </div>
);
export default ChatBubble;
