import React, { Component } from 'react';
import { Link } from 'react-router';
import PageHeader from '../components/PageHeader';
import user from '../util/userManagement';

export default class Intro extends Component {
  componentDidMount() {
    user.setAppReady();
  }

  render() {
    return (
      <div className="Settings page Intro">
        <PageHeader pageTitle="Welkom!" showLogo={true} />
        <p style={{ paddingTop: 0 }}>
          Nettie Goedemorgen is nu ingesteld voor de groep<br /><b>{user.getGroupName()}</b>.
        </p>
        <p>Wissel tussen de instellingen en het hoofdscherm door op Nettie te klikken.</p>
        <p><Link to="/" className="button">Naar hoofdscherm</Link></p>
      </div>
    );
  }
}
