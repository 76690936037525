import React, { Component } from "react";
import { browserHistory } from 'react-router';
import { Link, withRouter } from "react-router";
import { ArrowLeft } from "react-bytesize-icons";
import classNames from "classnames";
import PulseLoader from 'react-spinners/PulseLoader';
import debug from 'debug';

import contact, { leaveUrl, ALLOWED_ORIGIN } from "../util/serviceaddress";
import user from "../util/userManagement";

import "../assets/css/spreekuur.css";

const log = debug('nettie');

class Intro extends Component {
  leavingTimer = null;

  constructor(props) {
    super();

    this.state = {
      isLeaving: false,
    }

    // this.onLeave = this.onLeave.bind(this);
    props.router.setRouteLeaveHook(props.route, () => this.onLeave());
  }

  /**
   * Make sure a 'Gesprek is afgerond' message is shown for one second before
   * transitioning to the next page.
   *
   * If the user presses the back button of the browser twice, the action
   * is executed without the delay.
   *
   * @param {*} event
   */
  onLeave() {
    log('onLeave: isLeaving: %o, timer: %o', this.state.isLeaving, this.leavingTimer);

    if (this.state.isLeaving) {
      if (this.leavingTimer !== null) {
        clearTimeout(this.leavingTimer);
      }

      // True, transition may proceed
      return true;
    }

    this.setState({ isLeaving: true });

    this.leavingTimer = setTimeout(() => browserHistory.push('/'), 1500);

    // False, transition should be cancelled (preventDefault)
    return false;
  }

  componentDidMount() {
    const listener = ({ origin, data = { payload: { } } }) => {
      if (origin !== ALLOWED_ORIGIN) {
        return;
      }

      log('Received: %o from %o', data, origin);

      if (data.type === 'PAGE.CHANGED' && data.payload.name === 'conversationEnd') {

        // calling onLeave() won't leave the page after 1500ms so we do it manually
        setTimeout(() => {
          browserHistory.push('/')
        }, 1500);
      }
    }

    window.addEventListener('message', listener);

    this.unsubscribe = () => {
      window.removeEventListener('message', listener);
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const groupName = user.getGroupName();

    return (
      <div className="page Spreekuur">
        <div className="iframecontainer">
          <div className="iframeRoundedCornerWrap">
            <iframe
              style={{ width: "calc(100% - 40px" }}
              scrolling="no"
              src={!this.state.isLeaving ? `${contact.key}${groupName}` : leaveUrl}
              title="Spreekuur"
              sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin"
              allow="microphone; camera"
            />
          </div>

          <Link to="/" className={classNames({ 'button': true, 'pending': this.state.isLeaving })}>
            {!this.state.isLeaving && (<><ArrowLeft width={20} height={20} color="#fff" /> Terug</>)}
            <PulseLoader sizeUnit={"px"} size={10} color={'#03cebd'} loading={this.state.isLeaving} />
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Intro)