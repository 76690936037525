import { getData, setData } from './storage';
import defaultSkypeContact from './serviceaddress';

const storeUser = ({ userId, phonenumbers, skypenames, group }) => {
  const userData = {
    phonenumbers,
    skypenames,
    group,
  };

  setData(userId, userData);
};

const createUser = data => {
  setData('user', data);

  const storedUserData = getData(data.user_Id) || false;

  if (storedUserData) {
    setPhoneNumbers(storedUserData.phonenumbers);
    setSkypeNames(storedUserData.skypenames);
    setGroup(storedUserData.group);
    setAppReady();
  }
};
const deleteUser = () => {
  storeUser({ userId: getId(), phonenumbers: getPhoneNumbers(), skypenames: getSkypeNames(), group: getGroup() });

  ['user', 'group', 'phonenumbers', 'skypenames', 'appReady'].forEach(data => {
    setData(data, null);
  });
};

const getUser = () => getData('user') || {};
const isLoggedIn = () => !!getUser().access_token || false;

const getName = () => getUser().userName || false;
const getAuth = () => getData('user').access_token || false;
const getId = () => getUser().user_Id || false;
const getImage = () => getUser().image || false;

const setPhoneNumbers = data => setData('phonenumbers', data);
const getPhoneNumbers = () => getData('phonenumbers') || [];

const setSkypeNames = data => setData('skypenames', data.filter(skype => skype.key !== defaultSkypeContact.key));
const getSkypeNames = () => [defaultSkypeContact, ...(getData('skypenames') || [])];

const setGroup = data => setData('group', data);
const getGroup = () => getData('group');
const getGroupName = () => getGroup().Name;
const getGroupId = () => getGroup().Id;

const setAppReady = () => setData('appReady', true);

/**
 * Validate if user setup is valid, returns false if not
 */
function appReady() {
  if (!getData('appReady')) {
    return false;
  }

  if (!getGroup()) {
    return false;
  }

  return true;
};

const user = {
  createUser,
  deleteUser,
  isLoggedIn,

  getName,
  getAuth,
  getId,
  getImage,

  setPhoneNumbers,
  getPhoneNumbers,

  setSkypeNames,
  getSkypeNames,

  setGroup,
  getGroupName,
  getGroupId,

  setAppReady,
  appReady,
};

export default user;
