import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import PageHeader from '../../components/PageHeader';
import user from '../../util/userManagement';

export default class SkypeName extends Component {
  constructor() {
    super();
    this.state = {
      skypenames: user.getSkypeNames(),
      error: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({ skypenames: user.getSkypeNames() });
  }

  handleSubmit = e => {
    e.preventDefault();
    document.body.scrollTop = 0;

    const name = e.target.elements[0].value;
    const key = e.target.elements[1].value;
    const type = e.target.elements[2].value;

    let error = false;
    const skypenames = this.state.skypenames;
    const shouldAdd = this.props.params.skype === 'new';
    const potentialMatch = skypenames.filter(x => x.key === key)[0];

    if (!name && !key) {
      error = 'Vul een naam en skypenaam in';
    } else if (!name) {
      error = 'Vul een naam in';
    } else if (!key) {
      error = 'Vul een skypenaam in';
    } else if (shouldAdd && potentialMatch) {
      error = `Skypenaam bestaat al voor ${potentialMatch.name}`;
    }

    if (error) {
      this.setState({ error });
    } else {
      if (shouldAdd) {
        skypenames.push({ key, name, type });
      } else {
        skypenames.forEach(skype => {
          if (skype.key === this.props.params.skype) {
            skype.key = key;
            skype.name = name;
            skype.type = type;
          }
        });
      }
      user.setSkypeNames(skypenames);
      browserHistory.push('/settings/skypenames');
    }
  };

  render() {
    const skypename = this.props.params.skype === 'new' ? '' : this.props.params.skype;
    let name = '';
    let type = '';
    const match = this.state.skypenames.filter(skype => skype.key === skypename);

    if (match.length) {
      name = match[0].name;
      type = match[0].type;
    }

    const { error } = this.state;

    return (
      <div className="skype page">
        <PageHeader pageTitle="Voeg Skype toe" />

        <form onSubmit={this.handleSubmit}>
          <label htmlFor="name">Naam</label>
          <input name="name" type="text" placeholder="Nettie" defaultValue={name} />

          <label htmlFor="skypename">Skypenaam</label>
          <input
            name="skypename"
            type="text"
            placeholder="nettie.skype"
            autoCapitalize="off"
            spellCheck="false"
            defaultValue={skypename}
          />

          <label htmlFor="skypetype">Type</label>
          <select name="skypetype" defaultValue={type}>
            <option value="skype">Skype</option>
            <option value="ms-sfb">Skype 4 Business</option>
          </select>

          <button className="button" type="submit">
            {name ? 'Opslaan' : 'Voeg toe'}
          </button>
          <p className="error">{error}</p>
        </form>
      </div>
    );
  }
}
