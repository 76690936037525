import React, { Component } from 'react';
import { RouteTransition } from 'react-router-transition';

import Header from './components/Header';
import Login from './modules/Login';

import user from './util/userManagement';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: user.isLoggedIn(),
      isUserReady: user.appReady(),
    };
  }

  componentWillReceiveProps() {
    this.setState({
      isLoggedIn: user.isLoggedIn(),
      isUserReady: user.appReady(),
    });
  }

  render() {
    const { isUserReady, isLoggedIn } = this.state;

    return (
      <div className="chattie">
        <div className="fadient" />
        {isUserReady && <Header />}
        <div className="pagecontainer">
          <RouteTransition
            runOnMount={false}
            pathname={this.props.location.pathname}
            atEnter={{ opacity: 1, translateX: 100 }}
            atLeave={{ opacity: 0, translateX: -100 }}
            atActive={{ opacity: 1, translateX: 0 }}
            mapStyles={styles => ({ key: 'x', opacity: `${styles.opacity}`, transform: `translateX(${styles.translateX}%)` })}
          >
            {isLoggedIn ? this.props.children : <Login />}
          </RouteTransition>
        </div>
      </div>
    );
  }
}
