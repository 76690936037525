import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { ChevronBottom } from 'react-bytesize-icons';

import PageHeader from '../../components/PageHeader';

import user from '../../util/userManagement';
import { getGroups } from '../../util/api';

export default class Group extends Component {
  eventHandlers = {
    onChangeGroup: (event) => this.onChangeGroup(event),
    onSubmit: (event) => this.onSubmit(event),
  };

  constructor() {
    super();
    this.state = {
      selectedGroupId: user.getGroupId(),
      groups: [],
      error: false,
      errorType: '',
    };
  }

  async componentWillMount() {
    this.setState({loading: true})
    const { groups } = await getGroups(user.getAuth(), user.getId());
    
    const defaultGroup = groups.length > 0 ? groups[0].Id : null;
    this.setState({ 
      groups, 
      selectedGroupId: this.state.selectedGroupId ? this.state.selectedGroupId : defaultGroup,
      loading: false 
    });
  }

  onChangeGroup(event) {
    this.setState({
      selectedGroupId: event.target.value
    });
  }

  getSelectedGroup() {
    return this.state.groups.filter(group => group.Id === this.state.selectedGroupId)[0];
  }

  onSubmit(event) {
    event.preventDefault();

    if (!this.state.selectedGroupId) {
      this.setState({
        error: true,
        errorType: 'Wilt u een groep selecteren?'
      });
      return false;
    }

    user.setGroup(this.getSelectedGroup());

    // if there are phonenumbers, go back to settings
    if (user.appReady()) {
      browserHistory.push('/settings');
    } else {
      browserHistory.push('/settings/phonenumbers/');
    }
  };

  render() {
    const { selectedGroupId, error, errorType, loading } = this.state;
    const hasGroups = !!(this.state.groups && this.state.groups.length);

    return (
      <div className="page">
        <PageHeader pageTitle="Kies groep" />

        {hasGroups && <>
          <form onSubmit={this.eventHandlers.onSubmit}>
            <label>Groepen</label>
            {error ? <p className="small-error">{errorType}</p> : ''}
            <div className="selectgroup">
              <select value={selectedGroupId} onChange={this.eventHandlers.onChangeGroup}>
                {this.state.groups.map(group => <option value={group.Id} key={group.Id}>{group.Name}</option>)}
              </select>
              <ChevronBottom width={28} height={50} color="#fff" />
            </div>
            <button className="button" type="submit">Kies groep</button>
          </form>
        </>}

        {!hasGroups && !loading && <>
          <p>U heeft momenteel nog geen groepen. Voeg een groep toe via de app om door te kunnen gaan.</p>
          <button className="button"  onClick={() => window.location.reload()}>Vernieuwen</button>
        </>} 
      </div>
    );  
  }
}
