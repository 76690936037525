import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';

import App from './App';
import Intro from './modules/Intro';
import Chat from './modules/Chat';
import Spreekuur from './modules/Spreekuur';
import Logout from './modules/Logout';
import Settings from './modules/settings/Settings';
import Group from './modules/settings/Group';
import PhoneNumbers from './modules/settings/PhoneNumbers';
import PhoneNumber from './modules/settings/PhoneNumber';
import SkypeNames from './modules/settings/SkypeNames';
import SkypeName from './modules/settings/SkypeName';

import './assets/css/style.css';

ReactDOM.render(
  <Router history={browserHistory}>
    <Route path="/" component={App}>
      <IndexRoute component={Chat}/>
      <Route path="/intro" component={Intro}/>
      <Route path="/spreekuur" component={Spreekuur}/>
      <Route path="/settings" component={Settings}/>
      <Route path="/settings/group" component={Group}/>
      <Route path="/settings/phonenumbers" component={PhoneNumbers}/>
      <Route path="/settings/phonenumbers/:number" component={PhoneNumber}/>
      <Route path="/settings/skypenames" component={SkypeNames}/>
      <Route path="/settings/skypenames/:skype" component={SkypeName}/>
    </Route>
    <Route path="/logout" component={Logout}/>
  </Router>,
  document.getElementById('root')
);


/**
 * Patch viewport height for mobile browsers.
 *
 * Source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
function patchViewPortSize() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

patchViewPortSize();

window.addEventListener('resize', () => {
  patchViewPortSize();
});