import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import PageHeader from '../../components/PageHeader';
import user from '../../util/userManagement';

export default class PhoneNumber extends Component {
  constructor() {
    super();
    this.state = {
      phonenumbers: user.getPhoneNumbers(),
      error: false,
    };
  }

  componentWillReceiveProps() {
    this.setState({ phonenumbers: user.getPhoneNumbers() });
  }

  handleSubmit = e => {
    e.preventDefault();
    document.body.scrollTop = 0;

    const name = e.target.elements[0].value;
    const key = e.target.elements[1].value;

    let error = false;
    const phonenumbers = this.state.phonenumbers;
    const shouldAdd = this.props.params.number === 'new';
    const potentialMatch = phonenumbers.filter(x => x.key === key)[0];

    if (!name && !key) {
      error = 'Vul een naam en nummer in';
    } else if (!name) {
      error = 'Vul een naam in';
    } else if (!key) {
      error = 'Vul een nummer in';
    } else if (shouldAdd && potentialMatch) {
      error = `Nummer bestaat al voor ${potentialMatch.name}`;
    }

    if (error) {
      this.setState({ error });
    } else {
      if (shouldAdd) {
        phonenumbers.push({ key, name });
      } else {
        phonenumbers.forEach(number => {
          if (number.key === this.props.params.number) {
            number.key = key;
            number.name = name;
          }
        });
      }
      user.setPhoneNumbers(phonenumbers);
      browserHistory.push('/settings/phonenumbers');
    }
  };

  render() {
    const phonenumber = this.props.params.number === 'new' ? '' : this.props.params.number;
    let name = '';
    const match = this.state.phonenumbers.filter(number => number.key === phonenumber);

    if (match.length) {
      name = match[0].name;
    }

    const { error } = this.state;

    return (
      <div className="number page">
        <PageHeader pageTitle="Voeg nummer toe" />

        <form onSubmit={this.handleSubmit}>
          <label>Naam</label>
          <input type="text" placeholder="Nettie" defaultValue={name} />
          <label>Telefoonnummer</label>
          <input type="tel" placeholder="061234567" defaultValue={phonenumber} />
          <button className="button" type="submit">
            {name ? 'Opslaan' : 'Voeg toe'}
          </button>
          <p className="error">{error}</p>
        </form>
      </div>
    );
  }
}
