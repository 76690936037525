import React, { Component } from 'react';
import { Link } from 'react-router';
import { ArrowRight } from 'react-bytesize-icons';

import PageHeader from '../../components/PageHeader';
import SettingsItem from '../../components/SettingsItem';

import user from '../../util/userManagement';

import '../../assets/css/settings.css';

export default class PhoneNumbers extends Component {
  constructor() {
    super();
    this.state = {
      phonenumbers: user.getPhoneNumbers() || [],
    };
  }

  componentWillReceiveProps() {
    this.setState({ phonenumbers: user.getPhoneNumbers() });
  }

  componentWillUpdate(nextProps, nextState) {
    user.setPhoneNumbers(nextState.phonenumbers);
  }

  delete = key => {
    const personToDelete = this.state.phonenumbers.filter(number => number.key === key)[0].name;

    const shouldDelete = window.confirm(`${personToDelete} verwijderen?`);
    if (shouldDelete) {
      const phonenumbers = this.state.phonenumbers.filter(number => number.key !== key);
      this.setState({ phonenumbers });
    }
  };

  render() {
    const list = this.state.phonenumbers.map(number =>
      <SettingsItem name={number.name} key={number.key} val={number.key} type="phonenumbers" delete={this.delete} />,
    );

    return (
      <div className="page">
        <PageHeader pageTitle="Telefoonnummers" />
        <div className="settingslist">
          {list}
        </div>

        <Link to="/settings/phonenumbers/new" className="button">Nieuwe toevoegen</Link>
        
        <div className="next">
          <Link to="/settings/skypenames" className="button">
            Volgende <ArrowRight width={32} height={20} color="#fff" />
          </Link>
        </div>
      </div>
    );
  }
}
