import React, { Component } from 'react';
import { Link } from 'react-router';
import { ArrowRight } from 'react-bytesize-icons';

import PageHeader from '../../components/PageHeader';
import SettingsItem from '../../components/SettingsItem';

import user from '../../util/userManagement';
import defaultSkypeContact from '../../util/serviceaddress';

import '../../assets/css/settings.css';

export default class SkypeNames extends Component {
  constructor() {
    super();
    this.state = {
      skypenames: user.getSkypeNames() || [],
    };
  }

  componentWillReceiveProps() {
    this.setState({ skypenames: user.getSkypeNames() });
  }

  componentWillUpdate(nextProps, nextState) {
    user.setSkypeNames(nextState.skypenames);
  }

  delete = key => {
    const personToDelete = this.state.skypenames.filter(number => number.key === key)[0].name;

    const shouldDelete = window.confirm(`${personToDelete} verwijderen?`);
    if (shouldDelete) {
      const skypenames = this.state.skypenames.filter(number => number.key !== key);
      this.setState({ skypenames });
    }
  };

  render() {
    const list = this.state.skypenames.map(number =>
      <SettingsItem
        name={number.name}
        key={number.key}
        val={number.key}
        type="skypenames"
        delete={this.delete}
        fixed={number.key === defaultSkypeContact.key ? true : false}
      />,
    );

    return (
      <div className="page">
        <PageHeader pageTitle="Skypenamen" />
        <div className="settingslist">
          {list}
        </div>
        <Link to="/settings/skypenames/new" className="button">Nieuwe toevoegen</Link>

        {!user.appReady() &&
          <div className="next">
            <Link to="/intro" className="button">Volgende <ArrowRight width={32} height={20} color="#fff" /></Link>
          </div>}
      </div>
    );
  }
}
