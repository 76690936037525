import format from 'date-fns/format';
import { browserHistory } from 'react-router';

const API = '/api';

export const handleLogin = async (username, password) => {
  try {
    const response = await fetch(`${API}/Token`, {
      method: 'post',
      body: `grant_type=password&username=${username}&password=${password}`,
    });
    const data = await response.json();
    //store data
    return data;
  } catch (e) {
    return e;
  }
};

export const getGroups = async (access_token, user) => {
  try {
    const response = await fetch(`${API}/users/${user}/groups`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const data = await response.json();
    // store data
    return data;
  } catch (e) {
    browserHistory.push('/logout');
    return e;
  }
};

export const getEvents = async (access_token, group) => {
  const date = format(new Date(), 'YYYY-MM-DD');
  try {
    const response = await fetch(`${API}/groups/${group}/calendar/${date}/`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    const data = await response.json();
    return data.events;
  } catch (e) {
    browserHistory.push('/logout');
    return e;
  }
};
