import React, { Component } from 'react';
import { Link } from 'react-router';
import { Close } from 'react-bytesize-icons';

import '../assets/css/settings.css';

export default class SettingsItem extends Component {
  delete = e => {
    e.preventDefault();
    this.props.delete(this.props.val);
  };

  render() {
    return (
      <div className="settingsitem">
        <Link to={!this.props.fixed ? `/settings/${this.props.type}/${this.props.val}` : ''}>
          <b>{this.props.name}</b>
          <span>{!this.props.fixed ? this.props.val : ` `}</span>
        </Link>

        {!this.props.fixed
          ? <button className="delete-item" onClick={this.delete}><Close width={10} height={10} color="#fff" /></button>
          : ''}
      </div>
    );
  }
}
