const key = `https://beeldcontact.digi-connect.nl/WaitingRoom.html?logoutButtonVisibility=hidden&roomId=Nettie&clientName=`;
const name = "Dichtbij";
export const leaveUrl = 'https://beeldcontact.digi-connect.nl/conversationEnd.html';

// // Uncomment when debugging with a local iframe
// export const ALLOWED_ORIGIN = 'http://localhost:8080';
export const ALLOWED_ORIGIN = 'https://beeldcontact.digi-connect.nl';

const contact = { key, name };

export default contact;
