import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { Eye } from 'react-bytesize-icons';

import PageHeader from '../components/PageHeader';
import { handleLogin } from '../util/api';
import user from '../util/userManagement';

import '../assets/css/login.css';

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      error: false,
      errorType: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();
    document.body.scrollTop = 0;

    const email = e.target.elements[0].value;
    const password = e.target.elements[1].value;
    const userData = await handleLogin(email, password);

    if (userData.error) {
      this.setState({ error: true, errorType: 'Controleer uw inloggegevens.' });
    } else if (Object.keys(userData).length === 0) {
      this.setState({ error: true, errorType: 'Er is een probleem met de server, probeer het later nogmaals.' });
    } else {
      this.setState({ error: false });

      await user.createUser(userData);
      if(user.appReady()) {
        browserHistory.push('/');
      } else {
        browserHistory.push('/settings/group');
      }
    }
  }

  toggleShowingPassword = e => {
    e.preventDefault();
    this.setState({ show: !this.state.show });
  };

  render() {
    const { show, error, errorType } = this.state;
    return (
      <div className="login page">
        <PageHeader pageTitle="Nettie Goedendag" showLogo={true} />
        <form onSubmit={this.handleSubmit}>
          <label>E-mailadres</label>
          <input type="email" placeholder="naam@adres.nl" />
          <label>Wachtwoord</label>
          <input type={show ? 'text' : 'password'} placeholder="geheim!" />
          <button type="button" className="toggle" onClick={this.toggleShowingPassword}>
            <Eye width={30} height={30} color="#fff" />
          </button>
          <button className="button" type="submit">Log in</button>
          {error ? <p className="error">Inloggen mislukt</p> : ''}
          {error ? <p className="small-error">{errorType}</p> : ''}
        </form>
      </div>
    );
  }
}
